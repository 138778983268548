<script lang="ts" setup>
  import type { PloneTileCards } from '~/types/Plone'
  import { formatText } from '~/utils/format-text'

  const props = defineProps<{
    data: PloneTileCards
  }>()

  const slidesPerView = props.data?.layout_columns ? parseInt(props.data.layout_columns) : 1

  const sizesMapping = {
    1: 'xs:100vw sm:100vw md:50vw lg:40vw xl:35vw xxl:32vw',
    2: 'xs:100vw sm:100vw md:50vw lg:33vw xl:29vw xxl:25vw',
    3: 'xs:100vw sm:100vw md:50vw lg:33vw xl:25vw xxl:20vw',
    4: 'xs:50vw sm:50vw md:25vw lg:25vw xl:16vw xxl:14vw',
    6: 'xs:50vw sm:50vw md:25vw lg:25vw xl:13vw xxl:12vw',
  }
</script>

<template>
  <app-section
    :class="['tile-carousel', { 'tile-carousel--with-title': !!data?.title }]"
    :title="data?.title"
    :description="data?.description"
    :read-more-link="data?.more_link?.['@id']"
    :read-more-text="data?.more_link_text || data?.more_link?.title">
    <template v-if="data.items">
      <app-slider
        v-if="data.items"
        full-width
        :slides="data.items"
        :slides-per-view="slidesPerView"
        :loop="slidesPerView < data.items.length">
        <template #default="{ slide }">
          <app-card>
            <template #media>
              <nuxt-link
                :to="slide.custom_url ? slide.custom_url : slide['@id']"
                :target="slide.custom_url ? '_blank' : null"
                :rel="slide.custom_url ? 'noopener' : null">
                <aspect-ratio ratio="1.42">
                  <nuxt-picture
                    v-if="slide.image"
                    :src="slide.image.download"
                    :sizes="sizesMapping[slidesPerView]"
                    :width="672"
                    :height="474"
                    :alt="slide.title"
                    quality="85"
                    fit="cover"
                    loading="lazy"/>
                </aspect-ratio>
              </nuxt-link>
            </template>
            <template #header>
              <div class="tile-carousel__slide-header">
                <div class="tile-carousel__slide-title u-fs--400">
                  <nuxt-link
                    :to="slide.custom_url ? slide.custom_url : slide['@id']"
                    :target="slide.custom_url ? '_blank' : null"
                    :rel="slide.custom_url ? 'noopener' : null">
                    {{ formatText(slide?.title) }}
                  </nuxt-link>
                </div>
                <div
                  class="tile-carousel__hide-link"
                  v-if="slide['@type'] === 'cka.project' && slide.profile">
                  {{ slide.profile?.title }}
                </div>
              </div>
            </template>
            <p v-if="data.show_descriptions">
              {{ formatText(slide?.description) }}
            </p>
          </app-card>
        </template>
      </app-slider>
    </template>
  </app-section>
</template>

<style scoped>
  .tile-carousel--with-title :deep(.app-slider) {
    margin-top: var(--gap-600);
    @media (--md) {
      margin-top: 0 !important;
    }
  }

  .tile-carousel__slide-header {
    display: flex;
    line-height: 1;
    flex-direction: column;
    @media (--md) {
      gap: var(--gap-300);
      flex-direction: row;
    }
  }

  .tile-carousel__slide-title {
    margin-bottom: var(--gap-300);
    margin-right: auto;
    @media (--md) {
      margin-bottom: 0;
    }
  }

  .tile-carousel__hide-link {
    color: var(--c-text);
    text-decoration: none!important;
  }

  .tile-carousel :deep(.app-section__header) {
    @media (min-width: 980px) and (max-width: 1264px) {
      padding-right: var(--gap-700);
    }
  }
</style>
